@font-face {
  font-family: 'Lato Regular';
  src:  url('../assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('../assets/fonts/Lato-Regular.woff') format('woff'),
        url('../assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold';
  src:  url('../assets/fonts/Lato-Bold.woff2') format('woff2'),
        url('../assets/fonts/Lato-Bold.woff') format('woff'),
        url('../assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Black';
  src:  url('../assets/fonts/Lato-Black.woff2') format('woff2'),
        url('../assets/fonts/Lato-Black.woff') format('woff'),
        url('../assets/fonts/Lato-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Light';
  src:  url('../assets/fonts/Lato-Light.woff2') format('woff2'),
        url('../assets/fonts/Lato-Light.woff') format('woff'),
        url('../assets/fonts/Lato-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Hairline';
  src:  url('../assets/fonts/Lato-Hairline.woff2') format('woff2'),
        url('../assets/fonts/Lato-Hairline.woff') format('woff'),
        url('../assets/fonts/Lato-Hairline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Medium';
  src:  url('../assets/fonts/Lato-Medium.woff2') format('woff2'),
        url('../assets/fonts/Lato-Medium.woff') format('woff'),
        url('../assets/fonts/Lato-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.h1-style {
  font-family: Lato Regular;
  font-size: 36px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: #222222;
}

.h2-style {
  font-family: Lato Regular;
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.9px;
  color: #222222;
}

.h3-style {
  font-family: Lato Regular;
  font-size: 16px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  color: #222222;
}

.all-caps-label {
  font-family: Lato Regular;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #555555;
  color: var(--greyish-brown);
}

body {
  font-family: Lato Regular;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #222222;
}

.large-caption {
  font-family: Lato Regular;
  font-size: 8px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #222222;
}

.small-caption {
  font-family: Lato Regular;
  font-size: 6px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #222222;
}

.extra-small-caption {
  font-family: Lato Regular;
  font-size: 5px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.text-light {
  font-family: Lato Light;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #222222;
}
