@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '_variables.scss';
@import '_colors.scss';
@import '_fonts.scss';

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: #4C5AEA !important;
  --mdc-checkbox-selected-hover-icon-color: #707BEE !important;
  --mdc-checkbox-selected-icon-color: #707BEE !important;
  --mdc-checkbox-selected-pressed-icon-color: #4C5AEA !important;
  --mdc-checkbox-selected-checkmark-color: #fff !important;
}

.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
  height: 0;
  width: 0;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #333333;
}

.semi-bold {
  font-weight: 600;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: black !important;
}

.mat-calendar-body-cell {
  font-weight: 100 !important;
}

/*.mat-calendar-controls .mat-mdc-button .mdc-button__label {
  font-weight: 100 !important;
}*/
/*#region Scrollbar*/
*::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #A2A3AB;
  border-right: 4px solid white;
  border-left: 8px solid white;
  border-top-right-radius: 10px 7px;
  border-top-left-radius: 16px 8px;
  border-bottom-right-radius: 10px 7px;
  border-bottom-left-radius: 16px 8px;
}

*::-webkit-scrollbar-thumb:hover {
  border-left: 12px solid #A2A3AB;
  border-top-right-radius: 17px 8px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 17px 10px;
  -webkit-transition: all 0.25s ease-in-out, border-left 0.25s ease-in-out,border-top-right-radius 0.25s,border-top-left-radius 0.25s,border-bottom-left-radius 0.25s,border-bottom-right-radius 0.25s;
  transition: all 0.25s ease-in-out, border-left 0.25s ease-in-out,border-top-right-radius 0.25s,border-top-left-radius 0.25s,border-bottom-left-radius 0.25s,border-bottom-right-radius 0.25s;
}

/*#endregion*/

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #5E5F68;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #5E5F68;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: unset !important;
}

.mat-ripple-element {
  background-color: unset !important;
}

.mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {
  opacity: 0;
}

.snackbars {
  position: fixed;
  z-index: 99999;
  display: flex;
  flex-direction: column;
}

.snackbars .snack {
  background: #323232;
  color: #fff;
  position: relative;
  min-width: 288px;
  max-width: 568px;
  border-radius: 2px;
  padding: 14px 0 14px 24px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.snack .snack-text {
  word-wrap: break-word;
  overflow: hidden;
  margin-right: 24px;
}

.snack .snack-action {
  text-transform: uppercase;
  margin-left: auto;
  cursor: pointer;
  color: #2196F3;
  margin-right: 24px;
}

.snackbars.bottom-left {
  left: 30px;
  bottom: 20px;
  align-items: flex-start;
}

.snackbars.bottom-center {
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
  align-items: center;
}

.snackbars.bottom-right {
  right: 30px;
  bottom: 20px;
  align-items: flex-end;
}

.snackbars.top-left {
  left: 30px;
  top: 30px;
  align-items: flex-start;
}

.snackbars.top-center {
  left: 50%;
  transform: translate(-50%, 0);
  top: 30px;
  align-items: center;
}

.snackbars.top-right {
  right: 30px;
  top: 30px;
  align-items: flex-end;
}

html, body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0px auto !important;
}

input::-ms-clear {
  display: none;
}

material-icons.md-18 {
  font-size: 18px;
  color: #5f66b0;
}

.material-icons.md-24 {
  font-size: 24px;
  color: #5f66b0;
}

.material-icons.md-25 {
  font-size: 25px;
}

.material-icons.md-36 {
  font-size: 36px;
  color: #5f66b0;
}

.material-icons.md-48 {
  font-size: 48px;
}

.mat-mdc-progress-bar-fill::after {
  background-color: #5f66b0;
}

.mat-mdc-progress-bar-buffer {
  background: #E4E8EB;
}

.mat-mdc-progress-bar {
  position: fixed !important;
  top: 0;
  border-radius: 2px;
  z-index: 100000;
}

main {
  height: 100%;
}

nav {
  margin-top: 16px;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #FFD657;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #F39B00;
}

nav a {
  background-color: $nav-button-color;
  color: white;
  padding: 8px 16px;
  margin: 8px;
  vertical-align: middle;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
}

Utilities
.mdc-card__primary-action {
  cursor: auto;
}

.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  background-color: transparent !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.no-pointer-events {
  pointer-events: none;
}

.all-pointer-events {
  pointer-events: all !important;
}

.position-fixed {
  position: fixed !important;
}

.position-relative {
  position: relative;
}

.hidden {
  display: none !important;
}

.br-0 {
  border-right: none !important;
}

.bb-0 {
  border-bottom: none !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.grid-gap-y-0 {
  grid-row-gap: 0 !important;
}

.grid-gap-y-1 {
  grid-row-gap: 0.5rem !important;
}

.grid-gap-y-2 {
  grid-row-gap: 1rem !important;
}

.grid-gap-y-3 {
  grid-row-gap: 1.5rem !important;
}

.grid-gap-y-4 {
  grid-row-gap: 2rem !important;
}

.grid-gap-y-5 {
  grid-row-gap: 2.5rem !important;
}

.grid-gap-x-0 {
  grid-column-gap: 0 !important;
}

.grid-gap-x-1 {
  grid-column-gap: 0.5rem !important;
}

.grid-gap-x-2 {
  grid-column-gap: 1rem !important;
}

.grid-gap-x-3 {
  grid-column-gap: 1.5rem !important;
}

.grid-gap-x-4 {
  grid-column-gap: 2rem !important;
}

.grid-gap-x-5 {
  grid-column-gap: 2.5rem !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.mr-3 {
  margin-right: 2rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.ml-3 {
  margin-right: 2rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-decimal5 {
  padding-right: 0.5rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pt-5 {
  padding-top: 2.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.px-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pl-3 {
  padding-left: 1.5rem !important;
}

.pl-4 {
  padding-left: 2rem !important;
}

.pl-5 {
  padding-left: 2.5rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.grid-gap-0 {
  grid-gap: 0 !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.min-height-0 {
  min-height: 0 !important;
}

.min-height-1 {
  min-height: 0.5rem !important;
}

.box-shadow-2px {
  box-shadow: 0px 2px 0px 0px #909090;
}

.ht-unset {
  height: unset !important;
}

.width-100 {
  width: 100%;
}

.color-navy-blue {
  color: #3B3F6E;
}

.position-relative {
  position: relative;
}

.mdc-dialog {
  z-index: 10;
}

[hidden] {
  display: none !important;
}

.button-like-text {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.error-border-color {
  border-color: #b00020 !important;
}

.error-border-color {
  input:focus,
  select:focus,
  textarea:focus {
    border-color: unset !important;
  }
}

.error-border-color:focus {
  border-color: unset !important;
}

.mdc-text-field,
.mdc-select {
  height: 37px !important;

  .ng-touched.ng-invalid:not(.mdc-text-field--disabled):not(:focus) ~ .mdc-notched-outline__idle {
    border-color: #b00020;
  }

  .ng-valid:not(:focus) ~ .mdc-notched-outline__idle {
    border-color: rgba(0, 0, 0, 0.24);
  }

  .mdc-text-field__input.disabled ~ .mdc-notched-outline__idle {
    background: rgba(0, 0, 0, 0.12) !important;
    border-color: transparent !important;
    opacity: 0.48 !important;
  }

  .ng-touched.ng-invalid:not(.mdc-select--disabled):not(:focus) ~ .mdc-notched-outline__idle {
    border-color: #b00020 !important;
  }

  .ng-valid:not(:focus) ~ .mdc-notched-outline__idle {
    border-color: rgba(0,0,0,0.12) !important;
  }

  .mdc-select__native-control.disabled ~ .mdc-notched-outline__idle {
    background: rgba(0, 0, 0, 0.12) !important;
    border-color: transparent !important;
    opacity: 0.48 !important;
  }

  .mdc-text-field__input {
    letter-spacing: 0 !important;
  }
}

.mdc-select.mdc-select--outlined {
  select {
    font-weight: 400 !important;
    font-size: 16px !important;
    letter-spacing: 0.5px !important;
    padding: 7px 25px 10px 12px !important;
  }

  select.act-as-label {
    opacity: 0.6 !important;
    font-weight: 400 !important;
    letter-spacing: 0.5px !important;
  }

  select.ng-invalid.ng-touched {
    color: #b00020 !important;
    opacity: 1 !important;

    option {
      color: #b00020 !important;
    }
  }

  select ~ .mdc-notched-outline__idle {
    border-color: rgba(0,0,0,0.24);
  }

  select:focus ~ .mdc-notched-outline__idle {
    border-color: #5F66B0 !important;
  }

  select:hover ~ .mdc-notched-outline__idle {
    border-color: rgba(0,0,0,0.87);
  }

  select.ng-invalid.ng-touched:not(:focus) ~ .mdc-notched-outline__idle {
    border-color: #b00020;
  }
}

.mdc-text-field:not(.date-picker-field) {
  .frequency-text {
    position: absolute !important;
    display: flex !important;
    align-items: center !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 0 10px 2px !important;
  }

  .mdc-text-field__input {
    height: 100%;
    padding: 0px !important;
    font-size: 16px !important;
    letter-spacing: 0px !important;
    box-sizing: border-box;
  }

  .frequency-text ~ .mdc-text-field__input {
    padding: 7px 10px 10px 25px !important;
  }

  .frequency-text.end {
    right: 0 !important;
  }
}

.mdc-text-field.search-input:not(.mdc-text-field--outlined) {
  .mdc-text-field__input {
    height: 100% !important;
    padding: 7px 50px 0 12px !important;
    font-size: 16px;
    letter-spacing: 0px;
  }
}

.mdc-text-field.mdc-text-field--outlined.date-picker-field {
  display: inline-flex;
  position: relative;

  input {
    width: 100% !important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2.5rem;
    color: rgba(0,0,0,.87);
    border: 1px solid rgba(0,0,0,0.24);
    border-radius: 5px;
    padding: 2px 7px;
    outline: none;
  }

  input:hover {
    border: 1px solid rgba(0,0,0,0.87);
  }

  input.ng-invalid.ng-touched {
    border: 1px solid #b00020;
  }

  input.ng-invalid.ng-touched .mat-datepicker-toggle .mat-icon {
    color: #b00020;
  }

  .mat-icon {
    font-size: 22px !important;
  }

  .mat-datepicker-toggle {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 2;
  }
}

.mdc-text-field--focused ~ .error {
  display: none !important;
}

.mdc-select {
  select.act-as-label {
    opacity: 0.6;
  }
}

//Fix unnecessary /double scrollbar on html
.cdk-global-scrollblock {
  overflow-y: auto;
}

.cdk-global-overlay-wrapper {

  .cdk-overlay-pane {
    width: 100%;

    .mat-mdc-snack-bar-container {
      background: none;
      max-width: 100%;
      min-width: 100%;

      .mat-simple-snackbar {
        color: #333333;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        justify-content: center;

        .mat-simple-snackbar-action {
          margin-left: 20px;

          .mat-button-wrapper {
            color: #5F66B0;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 1px;
          }
        }
      }
    }

    .mat-mdc-snack-bar-container.black-bottom-default {
      background: black;
      max-width: 365px;
      min-width: 265px;
      margin: 0 auto;
      padding: 10px 15px;
      margin-bottom: 32px;

      .mat-simple-snackbar {
        color: #FFFFFF;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        justify-content: flex-start;

        .mat-simple-snackbar-action {
          margin-left: 20px;

          .mat-button-wrapper {
            color: #7A81BE;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0px;
          }
        }
      }
    }

    .mat-mdc-snack-bar-container.transparent-bottom-default {
      background: none !important;
      max-width: 365px !important;
      min-width: 265px !important;
      padding: 10px 15px !important;
      margin-bottom: 32px !important;

      .mat-simple-snackbar {
        color: #FFFFFF;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        justify-content: flex-start;

        .mat-simple-snackbar-action {
          margin-left: 20px;

          .mat-button-wrapper {
            color: #7A81BE;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0px;
          }
        }
      }
    }

    .mat-mdc-snack-bar-container.transparent-top-default {
      background: transparent !important;
      max-width: 100% !important;
      padding: 0px !important;
      height: 64px !important;
      width: 100% !important;
      box-shadow: 0px 8px 24px rgba(32, 45, 74, 0.2);
      border-radius: 0 0 8px 8px !important;
      max-height: 64px !important;

      .mat-simple-snackbar {
        color: #FFFFFF;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        justify-content: flex-start;

        .mat-simple-snackbar-action {
          margin-left: 20px;

          .mat-button-wrapper {
            color: #7A81BE;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      .mat-mdc-snack-bar-container {
        padding: 15px 10px 45px;

        .mat-simple-snackbar {
          position: relative;
          justify-content: flex-start;

          .mat-simple-snackbar-action {
            position: absolute;
            bottom: -30px;
            right: 0;

            button {
              padding: 0;
              text-align: right;
            }
          }
        }
      }

      .mat-mdc-snack-bar-container.transparent-bottom-default {
        margin-bottom: 97px !important;
      }
    }
  }
}

.multi-select-dropdown {
  width: 100%;
  max-width: 300px;

  .cuppa-dropdown {
    .selected-list {
      .c-btn {
        width: auto !important;
        box-shadow: unset !important;
        background: #eee;
        border: none;
        padding: 12px !important;

        .c-list {
          .c-token {
            background: transparent !important;
            font-weight: 400 !important;
            font-size: 16px !important;
            line-height: 18px !important;
            color: rgba(0,0,0,.87) !important;
            margin-top: 0 !important;
            padding: 0 !important;
            padding-right: 15px !important;
            width: 85% !important;

            .c-label {
              max-width: 100%;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.5rem;
              color: rgba(0,0,0,.87);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .c-remove {
              display: none !important;
            }
          }
        }

        .c-list ~ .c-angle-down, .c-angle-up {
          display: none !important;
        }

        .c-angle-down {
          display: none !important;
        }

        .countplaceholder {
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          color: rgba(0,0,0,.87) !important;
          right: 30px !important;
        }
      }

      .c-btn:after {
        content: '\E5C5';
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translate(0, -50%);
        font-family: 'Material Icons';
        font-size: 20px;
      }

      .c-btn.disabled {
        background: rgba(95, 102, 176, 0.12);
        cursor: default;
      }
    }

    .dropdown-list {
      padding-top: 0;

      .arrow-up {
        display: none;
      }

      .list-area {
        .lazyContainer {
          span {
            li {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-top: 5px;
              padding-bottom: 5px;

              label {
                font-weight: 400;
                font-size: 1rem;
                line-height: 2.5rem;
                color: #333333;
                padding-left: 2em;
              }

              label:before {
                color: #5F66AE;
                border: 2px solid rgba(0,0,0,.54);
                border-radius: 2px;
              }

              input:checked + label:before {
                background: #5F66AE;
                border: 2px solid #5F66AE;
              }
            }

            li:hover {
              background: rgba(0, 0, 0, 0.08);
            }

            li.disabled {
              pointer-events: none;

              label {
                color: rgba(0,0,0,0.26);
              }

              label:before,
              input:checked + label:before {
                background: rgba(0, 0, 0, 0.26);
                border: 2px solid transparent !important;
              }

              label:after {
                color: #FFFFFF;
              }
            }
          }
        }

        .list-message {
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: #555555;
        }

        .list-grp {
          h4 {
            margin: 15px 0px 10px 0px;
          }
        }
      }
    }
  }
}

.multi-select-dropdown.background-white,
.multi-select-dropdown-background-white {
  .cuppa-dropdown {
    .selected-list {
      .c-btn {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.75rem;
        padding: 9px 12px !important;
        background-color: white;
        border: 1px solid rgba(0,0,0,.24);

        .c-list {
          .c-token {
            .c-label {
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.75rem;
            }
          }
        }

        span:not(.c-label):first-child {
          font-weight: 300;
          opacity: 0.8;
        }
      }

      .c-btn:hover {
        border: 1px solid rgba(0,0,0,.87);
      }

      .c-btn::after {
        right: 0;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.52);
      }
    }

    .dropdown-list {
      .list-area {
        box-shadow: none;

        .lazyContainer {
          max-height: 210px !important;

          span {
            .pure-checkbox {
              input + label:after {
                left: 1px;
              }
            }
          }
        }

        .pure-checkbox {
          input[type="checkbox"] + label {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: rgba(0,0,0,.87);
          }

          input[type="checkbox"] + label:before {
            box-sizing: content-box;
            content: '';
            color: #5F66B0;
            position: absolute;
            top: 50%;
            left: -3px;
            width: 14px;
            height: 14px;
            margin-top: -9px;
            border: 2px solid #5F66B0;
            border-radius: 2px;
            text-align: center;
            transition: all 0.4s ease;
          }

          input[type="checkbox"]:checked + label:before {
            background: #5F66B0;
          }

          input + label:after {
            background-color: transparent;
            top: 50%;
            left: 1px;
            width: 8px;
            height: 3px;
            margin-top: -4px;
            border-style: solid;
            border-color: #ffffff;
            background-color: #5F66B0;
            border-width: 0 0 3px 3px;
            border-image: none;
            transform: rotate(-45deg) scale(0);
          }
        }

        .pure-checkbox:first-child {
          border-bottom: none;
        }

        .pure-checkbox.select-all {
          border-bottom: 1px solid rgba(0,0,0,0.24);
          padding: 10px 25px;
        }

        .list-grp:not(:last-of-type) {
          border-bottom: 1px solid rgba(0,0,0,0.24);
        }
      }
    }
  }
}

.rate-type-field {
  .multi-select-dropdown {
    .cuppa-dropdown {
      .dropdown-list {
        .list-area {
          .lazyContainer {
            span {
              .pure-checkbox:first-child {
                border-bottom: 1px solid #BEBEBE;
              }
            }
          }
        }
      }
    }
  }
}

.mat-menu-panel {
  .mat-menu-content {
    .mat-menu-item {
      .mat-icon ~ span {
        vertical-align: sub;
      }
    }
  }
}

.rate-type-field-border-red,
.expenses-border-red {
  .multi-select-dropdown {
    .cuppa-dropdown {
      .selected-list {
        .c-btn {
          border-color: #b00020 !important;
        }
      }
    }
  }
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  color: #333333;
  overflow: hidden;

  input,
  select,
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #333333;
  }

  button,
  .mdc-button,
  .mdc-tab {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 800;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  .mdc-dialog__body,
  .mdc-dialog__header__title,
  .mdc-top-app-bar__title,
  .mat-menu-item {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500;
    color: #333333;
  }

  .mdc-text-field__input,
  .mdc-form-field,
  .mdc-drawer--temporary .mdc-list-item,
  .mat-table,
  .mdc-typography,
  .mdc-select__native-control {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    color: #333333;
  }

  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500;
  }

  button,
  a,
  input,
  textarea,
  select,
  div,
  span,
  .material-icons {
    outline: none;
  }
  /* input: -webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    // border: 1px solid green;
    -webkit-text-fill-color: #5f66b0;
    // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }*/

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    input:focus {
      caret-color: #5F66B0 !important;
    }

    input:focus ~ .mdc-notched-outline__idle {
      border-color: #5F66B0 !important;
    }

    input:focus + .material-icons {
      color: #5F66B0 !important;
    }
  }

  .mdc-text-field--focused {
    ::-webkit-input-placeholder {
      color: #5F66B0 !important;
    }

    ::-moz-placeholder {
      color: #5F66B0 !important;
    }

    :-ms-input-placeholder {
      color: #5F66B0 !important;
    }

    ::placeholder {
      color: #5F66B0 !important;
    }
  }

  mdc-select--outlined:not(.mdc-select--disabled) {
    input:focus {
      caret-color: #5F66B0 !important;
    }

    input:focus ~ .mdc-notched-outline__idle {
      border-color: #5F66B0 !important;
    }

    input:focus + .material-icons {
      color: #5F66B0 !important;
    }
  }

  .mdc-select--focused {
    ::-webkit-input-placeholder {
      color: #5F66B0 !important;
    }

    ::-moz-placeholder {
      color: #5F66B0 !important;
    }

    :-ms-input-placeholder {
      color: #5F66B0 !important;
    }

    ::placeholder {
      color: #5F66B0 !important;
    }
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) + .error {
    display: none;
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) + .validation .error {
    display: none;
  }

  .mdc-select--focused:not(.mdc-select--disabled) + .error {
    display: none !important;
  }

  .mdc-select--focused:not(.mdc-select--disabled) + validation.error {
    display: none !important;
  }

  .wrapper {
    display: block;
    padding: 0 75px;
    min-height: calc(100vh - (80px + 80px));
    margin-top: 80px;
  }

  .form-container {
    .form-label {
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.48px;
      color: #333333;
      margin-bottom: 4px !important;
      line-height: 21px !important;
    }

    div.error {
      position: relative;
      left: 0rem;
      font-size: 0.6rem;
      color: #b00020;
      -webkit-transform: translateY(0%);
      -ms-transform: translateY(0%);
      -o-transform: translateY(0%);
      transform: translateY(0%);

      .marginTop {
        margin-top: 10px;
      }
    }

    div.validation {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      div.error {
        position: relative;
        left: 0rem;
        font-size: 0.6rem;
        color: #b00020;
        -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
        width: 50%;
      }

      .counter {
        text-align: right;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.423298px;
        color: rgba(51, 51, 51, 0.87);
        width: 50%;
      }
    }

    div.validation {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      div.error {
        position: relative;
        left: 0rem;
        font-size: 0.6rem;
        color: #b00020;
        -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
        width: 50%;
      }

      .counter {
        text-align: right;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.423298px;
        color: rgba(51, 51, 51, 0.87);
        width: 50%;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .wrapper {
      min-height: calc(100vh - (56px + 62px));
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media only screen and (max-width: 839px) and (min-width: 480px) {
    .wrapper {
      min-height: calc(100vh - (66px + 77px));
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.mat-menu-panel {
  margin-top: 8px;
  width: 100%;
  min-width: 250px !important;
  max-width: 400px !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100% !important;
}

::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

::-moz-placeholder {
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

:-ms-input-placeholder {
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

::placeholder {
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.offset-1 {
  grid-column-start: 2;
}

.mat-button-wrapper, .mat-button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800;
}

.mat-calendar {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  .mat-calendar-header {
    .mat-calendar-controls {
      .mat-button {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      }
    }
  }
}

.not-found {
  font-size: 4em;
  color: #fff;
  background: #4f5492;
}

.hide {
  visibility: hidden;
}

.theme-text-color {
  color: #5F66B0;
}

.ng2-carouselamos-container {
  width: 100%;
  max-width: 100% !important;
  margin: 0 auto;
  position: unset !important;

  .ng2-carouselamos-wrapper {
    width: 100% !important;
    padding: 16px;
  }

  .controls {
    top: 45% !important;

    button.left {
      position: absolute;
      cursor: pointer;
      left: 72px;

      .material-icons {
        font-weight: 800;
        font-size: 42px;
        color: #5F66B0;
      }
    }

    button.right {
      position: absolute;
      cursor: pointer;
      right: 72px;

      .material-icons {
        font-weight: 800;
        font-size: 42px;
        color: #5F66B0;
      }
    }
  }
}

@media only screen and (max-width: 840px) {
  .admin-dashboard-ng2-carouselamos {
    .ng2-carouselamos-container {
      .ng2-carouselamos-wrapper {
        .ng2-carouselamos {
          flex-wrap: wrap;
        }
      }

      .controls {
        display: none !important;
      }
    }
  }

  .ng2-carouselamos-container {
    padding-bottom: 50px;

    .controls {
      top: unset !important;
      bottom: 40px;

      button:first-of-type {
        left: -15px;
      }

      button:first-of-type:after {
        content: " PREV";
        position: absolute;
        top: 10px;
        left: 40px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #5F66B0;
      }

      button:last-of-type {
        right: -15px;
      }

      button:last-of-type:before {
        content: " NEXT";
        position: absolute;
        top: 10px;
        right: 40px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #5F66B0;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .ng2-carouselamos-container {
    .ng2-carouselamos-wrapper {
      padding: 5px;
    }
  }
}

.mat-nav-list.sorting-bottom-sheet-options-list {
  .mat-list-item {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    .mat-list-item-content {
      padding: 0;

      .mat-list-text {
        .mat-line {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          color: #333333;

          .material-icons {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .mat-nav-list.sorting-bottom-sheet-options-list {
    .mat-list-item {
      .mat-list-item-content {
        .mat-list-text {
          .mat-line {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

#region Snackbars
.snackbars {
  position: fixed;
  z-index: 999;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;

  ngx-snack {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
    animation: 0.2s ease-out 0s 1 slideInFromTop;
  }

  .snack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    background: #FFFFFF;
    max-width: 100%;
    border-radius: 0;
    color: #333333;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 0;

    .snack-text {
      word-wrap: break-word;
      overflow: hidden;
      margin-right: 24px;
    }

    .snack-action {
      text-transform: uppercase;
      margin-left: auto;
      cursor: pointer;
      color: #5F66B0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      margin-right: 24px;
    }
  }
}

.snackbars.bottom-left {
  left: 30px;
  bottom: 20px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start
}

.snackbars.bottom-center {
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  bottom: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.snackbars.bottom-right {
  right: 30px;
  bottom: 20px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

.snackbars.top-left {
  left: 30px;
  top: 30px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start
}

.snackbars.top-center {
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.snackbars.top-right {
  right: 30px;
  top: 30px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

#endregion
.f-d-column {
  flex-direction: column;
}

.circle {
  border-radius: 50%;
}

.o-hidden {
  overflow: hidden;
}

.h-100 {
  height: 100% !important;
}

.ht-unset {
  height: unset !important;
}

.w-50 {
  width: 50% !important;
}

.align-center {
  align-items: center;
}

.error {
  font-size: 0.6rem;
  color: #b00020;
}

.underline {
  text-decoration: underline;
}

.background-transparent {
  background: transparent;
}

.submit-btn.disabled {
  background-color: rgba(0, 0, 0, .12) !important;
  color: #FFFFFF !important;
}

.cursor-pointer.disabled {
  display: none !important;
}

textarea {
  resize: none;
}

.mdc-text-field--disabled, .mdc-select--disabled {
  opacity: 1 !important;

  input,
  select {
    color: #333333 !important;
  }

  .mdc-notched-outline__idle {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border-color: transparent !important;
    opacity: 0.48 !important;
  }
}

.mdc-checkbox--disabled {
  .mdc-checkbox__background {
    background-color: rgba(0, 0, 0, .12) !important;
    border-color: transparent !important;
  }
}

.mdc-text-field--disabled.date-picker-field {
  input {
    background-color: rgba(0, 0, 0, 0.06) !important;
    border-color: transparent !important;
    color: #333333 !important;
  }

  .mat-datepicker-toggle {
    .mat-icon-button {
      pointer-events: none !important;
      cursor: default !important;
    }
  }
}

form.disabled-form {
  input,
  textarea,
  select {
    color: #333333 !important;
    pointer-events: none !important;
    cursor: default !important;
  }

  .mdc-text-field,
  .mdc-select {
    .mdc-notched-outline__idle {
      background-color: rgba(0, 0, 0, 0.12) !important;
      border-color: transparent !important;
      opacity: 0.48 !important;
    }
  }

  .mdc-checkbox {
    .mdc-checkbox__background {
      background-color: rgba(0, 0, 0, .12) !important;
      border-color: transparent !important;
    }
  }

  .date-picker-field {
    input {
      background-color: rgba(0, 0, 0, 0.06) !important;
      border-color: transparent !important;
    }

    .mat-datepicker-toggle {
      .mat-icon-button {
        pointer-events: none !important;
        cursor: default !important;
      }
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      .mat-autocomplete-panel {
        .mat-option {
          height: 42px;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }

        .mat-option {
          .color-navy-blue {
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .calendar-mat-menu {
    width: 270px;
    margin-top: 30px;
    border-radius: 5px;

    .calendar-date-btn {
      width: 30px;
      height: 30px;
      border: none;
      background: none;
      color: #333333;
      margin: 6px 3px;
      border-radius: 5px;
      font-weight: 400;
    }

    .calendar-date-btn:last-child {
      width: 40px;
    }

    .selected-date {
      background-color: #5F66B0;
      color: #FFFFFF;
    }
  }
}

td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
  padding: 0 11px !important;
  word-break: break-all !important;
}

.break-word {
  word-break: break-all !important;
}

textarea {
  resize: none;
}

.mat-mdc-slider {
  .mat-mdc-slider-wrapper {
    .mat-mdc-slider-track-wrapper {
      .mat-mdc-slider-track-fill {
        background-color: #5F66B0;
      }
    }

    .mat-mdc-slider-ticks-container {
      .mat-mdc-slider-ticks {
        opacity: 1 !important;
      }
    }

    .mat-mdc-slider-thumb-container {
      .mat-mdc-slider-thumb {
        background-color: #5F66B0;
      }

      .mat-mdc-slider-thumb-label {
        background-color: #5F66B0;
      }
    }
  }
}

.mat-mdc-slider-horizontal {
  width: 100%;
  padding: 8px 25px 8px 20px !important;

  .mat-mdc-slider-wrapper {
    left: 20px !important;
    right: 25px !important;
  }
}

#eliminationPeriodForLTD.mat-mdc-slider-horizontal {
  padding: 8px 35px 8px 30px !important;

  .mat-mdc-slider-wrapper {
    left: 30px !important;
    right: 35px !important;
  }
}

#benefitPeriodForLTD.mat-mdc-slider-horizontal {
  padding: 8px 25px 8px 30px !important;

  .mat-mdc-slider-wrapper {
    left: 20px !important;
    right: 35px !important;
  }
}

#eliminationPeriodForSTD.mat-mdc-slider-horizontal {
  padding: 8px 35px 8px 50px !important;

  .mat-mdc-slider-wrapper {
    left: 30px !important;
    right: 55px !important;
  }
}

.mat-mdc-slider-horizontal:before {
  content: attr(data-min);
  position: absolute;
  top: 15px;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
}

.mat-mdc-slider-horizontal:after {
  content: attr(data-max);
  position: absolute;
  top: 15px;
  right: 0;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
}

.mat-menu-panel.add-class-form, .mat-menu-panel.search-class-form {
  text-align: center;

  .mat-menu-content {
    padding: 15px;

    .mdc-text-field {
      width: 100%;

      input {
        padding: 12px 12px 10px;
      }
    }

    .error {
      text-align: left;
      padding-left: 10px;
    }

    .submit-btn {
      background-color: #5F66AE !important;
      border-radius: 5px;
      margin: 1rem 0;
      padding: unset;
    }
  }
}

.mat-menu-panel.add-label-form,
.mat-menu-panel.search-label-form {
  text-align: center;

  .mat-menu-content {
    padding: 15px;

    .mdc-text-field {
      width: 100%;

      input {
        padding: 12px 12px 10px;
      }
    }

    .error {
      text-align: left;
      padding-left: 10px;
    }

    .submit-btn {
      background-color: #5F66AE !important;
      border-radius: 5px;
      margin: 1rem 0;
      padding: unset;
    }
  }
}

.mat-menu-panel.life-insurance-beneficiaries-container {
  margin-top: 0;

  .mat-menu-content {
    .tabs-container {
      display: flex;
      overflow-x: auto;
      max-width: 100%;
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);

      .tab {
        flex: 1;
        min-width: 20%;
        text-align: center;
        padding: 5px;
        cursor: pointer;

        .material-icons {
          color: #333333;
        }

        .amount {
          width: 100%;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.27px;
          color: #333333;
          text-align: center;
          margin: 5px 0 0;
        }
      }

      .tab:not(:first-of-type) {
        border-left: 2px solid rgba(0, 0, 0, 0.12);
      }

      .tab.active {
        .material-icons {
          color: #5F66B0;
        }

        .amount {
          color: #5F66B0;
        }
      }
    }

    .selected-beneficiary-info-container {
      .selected-beneficiary-info {
        padding: 25px;

        .count {
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 1.1px;
          color: #333333;
        }

        .field-label {
          display: flex;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #333333;
          margin-top: 20px;
        }

        .mdc-text-field {
          margin-top: 5px;

          .mdc-text-field__icon {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.38);
          }
        }

        .note {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #555555;
        }
      }
    }

    ::-webkit-scrollbar {
      height: 1px;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,0.12);
      outline: 1px solid rgba(0,0,0,0.12) !important;
    }
  }
}

.mat-menu-panel.sub-mat-menu {
  height: 100%;

  .mat-menu-content {
    .mat-menu-item {
      width: auto;
    }
  }
}

.mat-menu-panel.select-suboption-amount {
  margin-top: 0;
  min-width: 190px !important;
  //max-width: 190px !important;
  width: 190px !important;

  .mat-menu-content {
    max-height: 200px !important;

    .mat-menu-item {

      .mdc-radio {
        display: none !important;
      }
    }

    .mat-menu-item.selected {
      background-color: rgba(95, 102, 176, 0.12);
    }
  }
}

.mat-menu-panel.decision-plans-sort-menu {
  max-width: 340px !important;
}

@media only screen and (max-width: 840px) {
  .mat-menu-panel.select-suboption-amount {
    margin-top: 0;
    width: 300px !important;
    max-width: 300px !important;
  }
}

.cdk-drag-preview {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24);
  transform: rotate(-1deg);

  .mat-cell,
  .column {
    display: inline-flex;
    align-items: center;
    height: 48px;
    font-weight: 400;
    font-size: 14px;
    border-bottom: none;
    padding: 0 !important;
  }

  .edit-table-container {
    width: 100%;
  }

  .column.column-dragHandle {
    width: 7%;
    justify-content: center;
  }

  .column.schedule-name {
    width: 45%;
    justify-content: flex-start;
    vertical-align: top;

    .small-screen-range {
      display: none;
    }
  }

  .column.years-in-age-range {
    width: 20%;
    justify-content: flex-start;
    vertical-align: top;
  }

  .column.age-reduction-range {
    width: 20%;
    justify-content: flex-start;
    vertical-align: top;
  }

  .column.toggle-button {
    width: 5%;
    justify-content: center;
  }

  .body-row {
    width: 100%;

    .mat-cell.drag-handle {
      width: 10%;
      justify-content: center;
    }

    .mat-cell.name {
      width: 35%;
      justify-content: flex-start;

      .mobile-view {
        display: none;
      }
    }

    .mat-cell.mode {
      width: 25%;
      justify-content: flex-start;
    }

    .mat-cell.start-date {
      width: 22%;
      justify-content: flex-start;
    }

    .mat-cell.toggle-button {
      width: 5%;
      justify-content: center;
    }
  }
}

.cdk-drag-preview.enrollment-plans-dragger {
  .mat-cell.mat-column-checkBox {
    width: 16.5%;
    justify-content: flex-start;
  }

  .mat-cell.mat-column-planName {
    width: 22%;
    justify-content: flex-start;
  }

  .mat-cell.mat-column-planYear {
    width: 42.5%;
    justify-content: flex-start;
  }

  .mat-cell.mat-column-isActive {
    width: 12.5%;
    justify-content: flex-start;
  }

  .mat-cell.mat-column-dragHandle {
    width: 5.5%;
    justify-content: center;
  }
}

.cdk-drag {
  .cdk-column-dragHandle {
    text-align: center;
  }
}

.cdk-drag.cdk-drag-disabled {
  .cdk-column-dragHandle {
    i {
      cursor: default !important;
    }
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.plan-item-container {
  .enrollment-plan-item {
    .parent-container {
      border-bottom: 1px solid #909090;
      padding: 0 1.8rem;

      .field-container {
        min-height: 52px;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 0;

        .cuppa-dropdown {
          .selected-list {
            .c-btn {
              width: 100% !important;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}

.width-100 {
  width: 100%;
}

.multiple-date-picker {
  max-width: 300px;

  .picker-top-row {
    .picker-navigate-left-arrow.disabled,
    .picker-navigate-right-arrow.disabled {
      display: none;
    }

    .picker-month {
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #333333;
      text-align: left;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 10px;
    }
  }

  .picker-days-week-row {
    div {
      width: 12.28% !important;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #555555;
      margin: 2px;
    }
  }

  .picker-days-row {
    .picker-empty {
      background-color: transparent;
    }

    .picker-day {
      width: 12.28% !important;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.3px;
      line-height: 17px;
      color: #333333;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      margin: 2px;
    }

    .picker-day.today {
      background-color: transparent !important;
      color: #333333 !important;
    }

    .picker-day.picker-off.holiday:not(.picker-other-month),
    .picker-day.picker-off.holiday:not(.picker-other-month):hover {
      color: #FFFFFF !important;
      background-color: #191A21 !important;
      cursor: default !important;
      padding: 8px 0 !important;
    }

    .picker-day.picker-other-month,
    .picker-day.picker-other-month:hover,
    .picker-day.picker-off:not(.holiday),
    .picker-day.picker-off:not(.holiday):hover {
      color: #333333 !important;
      background-color: transparent !important;
      border-radius: unset !important;
      opacity: 0.3 !important;
      cursor: default !important;
    }

    .picker-day.picker-selected,
    .picker-day.click-selection-active,
    .picker-day.picker-selected.today,
    .picker-day.picker-selected:not(.holiday):hover {
      background-color: #5F66B0 !important;
      color: #FFFFFF !important;
    }

    .picker-day.picker-selected.picker-other-month,
    .picker-day.picker-selected.click-selection-active:not(.picker-off):not(.picker-selected) {
      background-color: unset !important;
      color: unset !important;
    }

    .picker-day:not(.picker-empty):not(.picker-off):not(.picker-selected):hover {
      background-color: rgba(0,0,0,0.12) !important;
      color: #333333 !important;
    }
  }
}

.mat-menu-panel.override-date-menu-panel {
  .menu-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding: 10px 15px;
    border-bottom: 1px solid #979797;
  }

  .menu-body {
    padding: 0 15px 10px;

    .change-from,
    .change-to {
      margin-top: 10px;

      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #5E5F68;
      }

      .value {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
      }

      .mdc-text-field {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 0;

        input {
          padding: 12px 12px 10px;
        }
      }

      .date-picker-field {
        display: inline-flex;
        align-items: center;
        width: 100%;

        input {
          width: 100% !important;
          line-height: 2.5rem;
          border-radius: 5px;
          border: 1px solid #909090;
          padding: 2px 10px;
          padding-right: 20px !important;
        }

        .mat-icon {
          font-size: 22px !important;
        }

        .mat-datepicker-toggle {
          position: absolute;
          right: 0px;
        }
      }
    }

    .submit-btn {
      width: 16rem;
      font-weight: 700;
      background-color: #5F66AE;
      border-radius: 5px;
      margin-top: 1rem;
      padding: 0;
    }
  }
}

.special-enrollment-progress-bar.mat-progress-bar {
  height: 24px;
  border-radius: 16px;

  .mat-progress-bar-fill::after {
    background-color: #3F4499;
  }
}

@media only screen and (max-width: 840px) {
  .special-enrollment-progress-bar.mat-progress-bar {
    height: 8px;
  }
}

.justify-center {
  justify-content: center !important;
}

.client-setup-next-button {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 250px;
  color: white !important;
  background-color: #5f66b0 !important;
  border-radius: 5px;
  height: 45px;
}

.payrolls-schedule-date-picker.disable-pointer-events {
  .multiple-date-picker {
    .picker-days-row {
      .picker-day {
        pointer-events: none;
      }
    }
  }
}

.mat-menu-panel.carrier-integration {
  min-width: 120px !important;
  max-width: 350px !important;

  .mat-menu-content {
    .custom-parent-div {
      width: auto;
      display: flex;

      .mat-menu-item {
        width: 100%;
        justify-content: flex-start;
        padding: 0;
        padding-right: 40px;
      }
    }
  }
}

.multiple-date-picker .picker-days-row .picker-day:not(.picker-empty):not(.picker-off):not(.picker-selected):hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: #333333;
  height: auto;
  padding: 8px 0;
}

.multiple-date-picker .picker-days-row .picker-day.picker-off:not(.future):not(.past), .multiple-date-picker .picker-days-row .picker-day.picker-off:not(.future):not(.past):hover {
  color: #FFFFFF;
  background-color: #191A21;
  cursor: default;
  height: auto;
  padding: 8px 0;
}

.multiple-date-picker .picker-days-row .picker-day.picker-selected, .multiple-date-picker .picker-days-row .picker-day.click-selection-active, .multiple-date-picker .picker-days-row .picker-day.picker-selected.today {
  background-color: #5F66B0;
  color: #FFFFFF;
  height: auto;
  padding: 8px 0;
}

.disabled-fields {
  input,
  textarea,
  select {
    color: #333333 !important;
    pointer-events: none !important;
    cursor: default !important;
  }

  .mdc-text-field,
  .mdc-select {
    .mdc-notched-outline__idle {
      background-color: rgba(0, 0, 0, 0.12) !important;
      border-color: transparent !important;
      opacity: 0.48 !important;
    }
  }

  .mdc-checkbox {
    .mdc-checkbox__background {
      background-color: rgba(0, 0, 0, .12) !important;
      border-color: transparent !important;
    }
  }

  .date-picker-field {
    input {
      background-color: rgba(0, 0, 0, 0.06) !important;
      border-color: transparent !important;
    }

    .mat-datepicker-toggle {
      .mat-icon-button {
        pointer-events: none !important;
        cursor: default !important;
      }
    }
  }
}

.mat-menu-panel.elimination-period-list-item, .mat-menu-panel.benefit-duration-list-item {
  margin-top: 0px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 9px 12px rgba(0, 0, 0, 0.14);
  min-width: 125px !important;

  .mat-menu-content {
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 15px;
  }
}

.m-icon-position {
  color: #5f66ae;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -25px;
}

.mdc-select {
  select.act-as-label {
    opacity: 0.6;
    font-weight: 300;
  }
}

.number-counter {
  .mdc-text-field {
    height: 64px !important;

    .mdc-text-field__input {
      font-size: 36px !important;
    }
  }
}

.premium-field {
  .mdc-text-field:not(.date-picker-field) {
    .mdc-text-field__input {
      font-size: 14px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 7px !important;
    }
  }
}

.hra-field {
  .hra-select-field {
    .chk-field-label {
      padding-left: 0 !important;
    }
  }
}

.mat-chip-list-wrapper {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: -4px;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .m-icon-position {
    color: #5f66ae;
    position: unset !important;
    top: 50%;
    transform: translate(0, -50%);
    right: -25px;
  }

  .mat-chip-list-wrapper {
    display: inline-block !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px;
    justify-content: center !important;
    white-space: nowrap !important;
    max-width: 100% !important;
    overflow-x: scroll !important;
  }
}

.elimination-field {
  .mdc-text-field {
    .mdc-text-field__input {
      padding: 7px 4px 12px 12px !important;
    }
  }
}

.mat-datepicker-toggle.h-100 {
  .mat-icon-button {
    height: 100%;
  }
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #ffffff !important;
  margin-left: 30px !important;
  opacity: 1;
}

.mat-standard-chip .mat-chip-remove.mat-icon {
  width: 22px !important;
  height: 22px !important;
}

.mat-chip .mat-chip-remove.mat-icon,
.mat-chip .mat-chip-trailing-icon.mat-icon {
  font-size: 22px !important;
}

.mdc-select--outlined .mdc-select__native-control {
  padding: 12px 6px 12px 16px !important;
  display: flex !important;
  border: none !important;
  background-color: transparent !important;
  z-index: 1 !important;

  .mat-select-trigger {
    display: inline-table;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }
}

.mat-select-trigger {
  display: inline-table;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  opacity: 0 !important;
}

/*.mat-multi-select-panel {
  transform-origin: 50% 24px 0px;
  font-size: 16px;
  opacity: 1;
  min-width: calc(100%+0px) !important;
  transform: scaleY(1);
}*/

.mat-radio-label-content {
  display: inline-block;
  order: 0;
  line-height: inherit;
  padding-left: 8px;
  padding-right: 0;
  box-sizing: border-box !important;
  white-space: normal !important;
}

.mat-radio-label {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start !important;
  white-space: nowrap;
  vertical-align: middle;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5f66ae;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #5f66ae;
  background-color: #5f66ae;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #5f66ae;
  opacity: .16;
}

.mat-checkbox.mat-accent.mat-checkbox-checked .mat-checkbox-outer-circle {
  border-color: #5f66ae;
}

.mat-checkbox.mat-accent .mat-checkbox-inner-circle {
  color: #5f66ae;
  background-color: #5f66ae;
}

.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #5f66ae;
  opacity: .16;
}

.mat-checkbox.mat-accent {
  .mat-checkbox-frame {
    border: 1px solid #333333;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: #5f66ae;
    border: 1px solid #5f66ae;
  }

  .mat-checkbox-checkmark-path {
    stroke: white !important;
  }
}

.mat-menu-panel.benefit-amount-list-item {
  margin-top: 0px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 9px 12px rgba(0, 0, 0, 0.14);

  .mat-menu-content {
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 15px;
  }
}

.mat-menu-panel.benefit-amount-list-item { // it it used to set the width of mat menu.
  min-width: 111px !important;
}

.align-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-with-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #ffffff !important;
  margin-left: 30px !important;
  opacity: 1;
}

.mat-standard-chip .mat-chip-remove.mat-icon {
  width: 22px !important;
  height: 22px !important;
}

.mat-chip .mat-chip-remove.mat-icon,
.mat-chip .mat-chip-trailing-icon.mat-icon {
  font-size: 22px !important;
}

.mdc-select--outlined .mdc-select__native-control {
  padding: 12px 6px 12px 16px !important;
  display: flex !important;
  border: none !important;
  background-color: transparent !important;
  z-index: 1 !important;

  .mat-select-trigger {
    display: inline-table;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }
}

.mat-select-trigger {
  display: inline-table;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  opacity: 0 !important;
}

/*.mat-multi-select-panel {
  transform-origin: 50% 24px 0px;
  font-size: 16px;
  opacity: 1;
  min-width: calc(100%+0px) !important;
  transform: scaleY(1);
}*/

.mat-radio-label-content {
  display: inline-block;
  order: 0;
  line-height: inherit;
  padding-left: 8px;
  padding-right: 0;
  box-sizing: border-box !important;
  white-space: normal !important;
}

.mat-radio-label {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start !important;
  white-space: nowrap;
  vertical-align: middle;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5f66ae;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #5f66ae;
  background-color: #5f66ae;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #5f66ae;
  opacity: .16;
}

.mat-checkbox.mat-accent.mat-checkbox-checked .mat-checkbox-outer-circle {
  border-color: #5f66ae;
}

.mat-checkbox.mat-accent .mat-checkbox-inner-circle {
  color: #5f66ae;
  background-color: #5f66ae;
}

.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #5f66ae;
  opacity: .16;
}

.mat-checkbox.mat-accent {
  .mat-checkbox-frame {
    border: 1px solid #333333;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: #5f66ae;
    border: 1px solid #5f66ae;
  }

  .mat-checkbox-checkmark-path {
    stroke: white !important;
  }
}

.z-index-5 {
  z-index: 5 !important;
}

.mdc-text-field:not(.date-picker-field).benefit-amount-container-child {
  .mdc-text-field__input {
    padding: 0px 15px 5px !important;
  }
}

.mdc-select--outlined .mdc-select__native-control {
  padding: 12px 6px 12px 16px !important;
  display: flex !important;
  border: none !important;
  background-color: transparent !important;
  z-index: 1 !important;

  .mat-select-trigger {
    display: inline-table;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }
}

.mat-select-trigger {
  display: inline-table;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.mat-accent .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-indeterminate {
  background: #5f66ae !important;
}

.mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  opacity: 0 !important;
}

.mat-multi-select-panel {
  transform-origin: 50% 24px 0px;
  font-size: 16px;
  opacity: 1;
  min-width: 100% !important;
  transform: scaleY(1);
}

.mat-label {
  position: absolute;
  left: 10px;
  top: 47%;
  transform: translate(0, -50%);
  box-sizing: content-box;
  pointer-events: none;
  overflow: hidden;
  opacity: 0.5;
  font-weight: 400;
  font-size: 14px;
}

.disabled.mdc-select--outlined .mdc-select__native-control {
  cursor: unset;
  pointer-events: none;
}

.justify-start {
  justify-content: flex-start !important;
}

.align-page-center {
  margin: 0 auto !important;
  max-width: 700px;
  width: 100%;
}

.italic-font {
  font-style: italic !important;
}

.white-background {
  background: #ffffff !important
}

.justify-end {
  justify-content: flex-end;
}
/*#region mat-tab-bar*/
.mat-tab-bar {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 25px auto;

  .tab-item {
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #5E5F68;
    background-color: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 0;
    border-right: none;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0;

    .mat-tab__icon-text {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      font-size: 16px;
      letter-spacing: 1.05px;
      padding-top: 0;
    }
  }

  .active {
    background-color: #4C5AEA;
    color: #FFFFFF;
    opacity: 1;
    border-color: #4C5AEA;
  }

  .tab-item:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .tab-item:last-of-type {
    border-right: 1px solid #E9E9E9;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .active:last-of-type {
    border-right: none;
  }
}
/*#endregion

.text-cap {
  text-transform: capitalize;
}

#region Full screen dialog*/
.mat-dialog-container__full-screen {
  .mat-mdc-dialog-container {
    height: 100%;
    max-height: unset;
    width: 100%;
    box-shadow: unset;
    border-radius: unset;
    overflow-x: hidden;
    background-color: #F8FBFD;
    padding: 0;

    .mat-mdc-dialog-title {
      font-size: 48px;
      line-height: 58px;
      margin-top: 40px;

      .mat-mdc-icon-button {
        position: absolute;
        right: 72px;
        top: 54px;
        color: #272E78;
        width: 40px;
        height: 40px;
      }

      .mat-mdc-icon-button.mat-mdc-back-button {
        left: 72px;
        display: flex;
        align-items: center;
      }

      .mat-mdc-icon-button.mat-icon__back-button {
        position: absolute;
        left: 72px;
        top: 54px;
        color: #272E78;

        img {
          height: 28px;
          width: 28px;
        }
      }
    }

    .container {
      width: 100%;
      position: unset;
      min-height: calc(100vh - 90px);
      padding: 48px;

      .mat-mdc-dialog-content {
        margin: 0 auto;
        padding: 24px;
        min-height: calc(100vh - 292px);
      }

      .mat-mdc-dialog-content.table-container {
        max-width: 1045px !important;
      }

      .mat-mdc-dialog-actions {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        background-color: #F8FBFD;
      }
    }
  }
}
/*#endregion
@import "c:\users\roshanpandey\source\repos\decision\client\clientapp\src\styles\fonts";
@import "c:\users\roshanpandey\source\repos\decision\client\clientapp\src\styles\fonts";*/
/*
//start region new css*/
.main-dialog-container {
  .sub-dialog-container {
    overflow-y: scroll;
    height: 41vh;

    .close-button {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .main-dialog-title {
      display: flex;
      width: 100%;
      justify-content: center;

      .dialog-title {
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .main-section {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .sub-section {
        width: 480px;
      }
    }
  }
}


.standerd-dialog-button-primary {
  width: 100% !important;
  color: white !important;
  height: 56px !important;
  border-radius: 56px;
  border: initial;
  background-color: #343DA0 !important;
  padding: 5px 25px;
  margin-right: 10px;
  box-shadow: 0px 2px 8px rgba(32, 45, 74, 0.2);
  cursor: pointer;
  height: 56px;
  max-width: 480px;
  color: white;
  text-transform: uppercase;
  margin: 16px 0;
}

.standerd-dialog-button-white {
  width: 100% !important;
  height: 56px !important;
  border-radius: 56px;
  border: 2px solid #333333;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(32, 45, 74, 0.2);
  cursor: pointer;
  max-width: 480px;
  text-transform: uppercase;
  margin: 16px 0;
}

.mt-20 {
  margin-top: 20px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 6px !important;
}

/*.mat-mdc-select-value {
  padding-top: 7px !important;
}*/

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  height: 48px !important;
}

.mdc-tab-indicator {
  .mdc-tab-indicator__content {
    height: 42px !important;
  }
}

mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 5px !important;
}

.mat-mdc-slider .mat-mdc-slider-wrapper .mat-mdc-slider-track-wrapper .mat-mdc-slider-track-fill {
  background-color: #4C5AEA !important;
}

.mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--active_fill {
  border-color: #4C5AEA !important;
  background-color: #4C5AEA !important;
}

.mat-mdc-slider .mdc-slider__track--active {
  height: 10px !important;
}

.mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
  background-color: #4C5AEA !important;
  height: 10px !important;
}

.mat-mdc-text-field-wrapper {
  background-color: white !important;
}

.mdc-slider {
  height: 19px !important;
  margin-top: 13px !important;

  .mdc-slider__thumb {
    height: unset !important;
    top: 50%;
    background-color: red !important;
  }
}

.mat-mdc-slider.mdc-slider--disabled {
  opacity: unset !important;

  .mdc-slider__thumb-knob {
    height: 19px !important;
    width: 19px !important;
    bottom: -16px !important;
    background-color: white !important;
    border: solid 5px #4C5AEA !important;
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding-bottom: 9px !important;
  padding: inherit;
}

.acceptanceClass {
  position: fixed;
  z-index: 500;
  bottom: 7px;
}

.cookies-dialog {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 15px;
  z-index: 3000;
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    max-width: unset !important;
    width: 100%;
    background-color: white !important;
  }
}
/*legend region new css*/

/*.mb-2{
    margin-bottom:1rem;
}
*/


.mat-mdc-form-field.common-margin.matform-field {
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }
}
